import * as Luigi from "../vehicles/Luigi";

export default {
    "Standard Kart M": Luigi.StandardKartM,
    "Classic Dragster": Luigi.ClassicDragster,
    "Wild Wing": Luigi.WildWing,
    "Super Blooper": Luigi.SuperBlooper,
    "Daytripper": Luigi.Daytripper,
    "Sprinter": Luigi.Sprinter,
    "Standard Bike M": Luigi.StandardBikeM,
    "Mach Bike": Luigi.MachBike,
    "Sugarscoot": Luigi.Sugarscoot,
    "Zip Zip": Luigi.ZipZip,
    "Sneakster": Luigi.Sneakster,
    "Dolphin Dasher": Luigi.DolphinDasher
}