import Grid from "@mui/material/Unstable_Grid2"
import React, {useState} from "react";
import "./CupGrid.css";
import cupIcons from "./CupIcons";
import CupItem from "./CupItem";

const CupGrid = ({selectedCup, setSelectedCup}) => {
    const handleCupClick = (cupId) => {
        setSelectedCup(cupId);
    }

    return (
        <Grid container className="cup-container" spacing={2}>
            {cupIcons.map((cup) => (
                <CupItem
                    key={cup.id}
                    cup={cup}
                    isSelected={selectedCup === cup.id}
                    onCupClick={() => handleCupClick(cup.id)}
                />
            ))}
        </Grid>
    )
}

export default CupGrid;