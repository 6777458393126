import {Route, Routes} from "react-router-dom";
import Admin from "./Admin";
import CTGPTop10 from "./CTGPTop10";
import Donate from "./Donate";
import Home from "./Home";
import Links from "./Links";
import Login from "./Login";
import PlayerMatchup from "./PlayerMatchup";
import PlayerStats from "./PlayerStats";
import RegionalTop10 from "./RegionalTop10";
import AddPlayer from "./Upload/AddPlayer";
import UploadGhost from "./Upload/UploadGhost";
import WorldwideTop10 from "./WorldwideTop10";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/worldwide-top-10" element={<WorldwideTop10/>}/>
            <Route path="/regional-top-10" element={<RegionalTop10/>}/>
            <Route path="/ctgp-top-10" element={<CTGPTop10/>}/>
            <Route path="/player-stats" element={<PlayerStats/>}/>
            <Route path="/player-matchup" element={<PlayerMatchup/>}/>
            <Route path="/upload-ghost" element={<UploadGhost/>}/>
            <Route path="/add-new-player" element={<AddPlayer/>}/>
            <Route path="/links" element={<Links/>}/>
            <Route path="/donate" element={<Donate/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/admin" element={<Admin/>}/>
        </Routes>
    )
}

export default AppRoutes;