import * as Koopa from "../vehicles/Koopa";

export default {
    "Standard Kart S": Koopa.StandardKartS,
    "Booster Seat": Koopa.BoosterSeat,
    "Mini Beast": Koopa.MiniBeast,
    "Cheep Charger": Koopa.CheepCharger,
    "Tiny Titan": Koopa.TinyTitan,
    "Blue Falcon": Koopa.BlueFalcon,
    "Standard Bike S": Koopa.StandardBikeS,
    "Bullet Bike": Koopa.BulletBike,
    "Bit Bike": Koopa.BitBike,
    "Magikruiser": Koopa.Magikruiser,
    "Jet Bubble": Koopa.JetBubble,
    "Quacker": Koopa.Quacker
}