import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import "./style/App.css";
import AppRoutes from "./components/AppRoutes";
import HeaderWithLocation from "./components/HeaderWithLocation";
import useToken from "./components/Login/Token";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const App = () => {
    return (
        <BrowserRouter>
            <HeaderWithLocation/>
            <AppRoutes/>
        </BrowserRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);