import {Box, Button, ButtonGroup, MenuItem, Select, ThemeProvider} from "@mui/material";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {darkTheme, yellowTheme} from "../../themes";
import TrackGhostsGrid from "./TrackGhostsGrid";

const AddVideo = () => {
    const categories = {"non-sc": "Non-SC", "sc": "SC", "glitch": "Glitch"};
    const [category, setCategory] = useState("non-sc");
    const [trackList, setTrackList] = useState([]);
    const [track, setTrack] = useState("");
    const [ghosts, setGhosts] = useState([]);

    useEffect(() => {
        axios.get("/api/get-track-list").then((response) => {
            setTrackList(response.data);
            setTrack(response.data[0])
        })
    }, []);

    const getGhosts = () => {
        axios.get("/api/get-ghosts", {
            params: {
                track: track,
                category: category
            }
        }).then((response) => {
            setGhosts(response.data);
        })
    }
    return (
        <ThemeProvider theme={darkTheme}>
            <Box className="center-box">
                <ButtonGroup>
                    <Select size="small" value={track} onChange={(e) => setTrack(e.target.value)}>
                        {trackList.map((track) => (
                            <MenuItem key={track} value={track}>{track}</MenuItem>
                        ))}
                    </Select>
                    <Select size="small" value={category} onChange={(e) => setCategory(e.target.value)}>
                        {Object.keys(categories).map((category) => (
                            <MenuItem key={category} value={category}>{categories[category]}</MenuItem>
                        ))}
                    </Select>
                    <Button onClick={getGhosts}>
                        Get ghosts
                    </Button>
                </ButtonGroup>
                {ghosts.length > 0 && <TrackGhostsGrid data={ghosts} category={category}/>}
            </Box>
        </ThemeProvider>
    )
}

export default AddVideo;