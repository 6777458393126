import {Box, Link} from "@mui/material";
import React from "react";
import "./Links.css";
import Layout from "../Upload/Layout";

const Links = () => {
    const links = [
        {
            href: "https://docs.google.com/spreadsheets/d/1hPgqNjI9blq9XJ-2Vw695uJ-742A659HT2urgJkHv_I",
            text: "Alternative Kart BKTs",
        },
        {
            href: "https://docs.google.com/spreadsheets/d/1bj7DPqrXYvU-B0UOeXLmSONGQOSLbkRKOr-z6JhvGSo",
            text: "Continental Top 10",
        },
        {
            href: "https://docs.google.com/spreadsheets/d/1NPxcO4yXDBkM3hQIZFKbS6gFKJPwDYXkldV8r28Sxp0",
            text: "Jetsetter BKTs",
        },
        {
            href: "https://docs.google.com/spreadsheets/d/1N0xCoEv2g6vIfksbp-guQIbdjPmNUpTUq0MZWoPRdvE/edit#gid=528298039",
            text: "Kart BKS",
        },
        {
            href: "https://docs.google.com/spreadsheets/d/1Skv9_chPxpCJ4ITsoYLx0s8-JapfiAMQI4HYRT8cpeA",
            text: "Kart WR History",
        },
        {
            href: "https://docs.google.com/spreadsheets/d/1N0xCoEv2g6vIfksbp-guQIbdjPmNUpTUq0MZWoPRdvE",
            text: "Top 10 Points",
        }
    ];

    return (
        <Layout title="Links">
            <Box className="links-container">
                {links.map((link, index) => (
                    <Link
                        key={index}
                        variant="body1"
                        className="link"
                        underline="hover"
                        target="_blank"
                        rel="noreferrer"
                        href={link.href}
                    >
                        {link.text}
                    </Link>
                ))}
            </Box>
        </Layout>
    )
}

export default Links;