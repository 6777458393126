import * as BabyPeach from "../vehicles/Baby Peach";

export default {
    "Standard Kart S": BabyPeach.StandardKartS,
    "Booster Seat": BabyPeach.BoosterSeat,
    "Mini Beast": BabyPeach.MiniBeast,
    "Cheep Charger": BabyPeach.CheepCharger,
    "Tiny Titan": BabyPeach.TinyTitan,
    "Blue Falcon": BabyPeach.BlueFalcon,
    "Standard Bike S": BabyPeach.StandardBikeS,
    "Bullet Bike": BabyPeach.BulletBike,
    "Bit Bike": BabyPeach.BitBike,
    "Magikruiser": BabyPeach.Magikruiser,
    "Jet Bubble": BabyPeach.JetBubble,
    "Quacker": BabyPeach.Quacker
}