import UploadIcon from "@mui/icons-material/Upload";
import {Box, ButtonGroup, IconButton} from "@mui/material";
import axios from "axios";
import {getCodeList} from "country-list";
import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import "./AddPlayer.css";
import CountryField from "./CountryField";
import Message from "./Message";
import PlayerField from "./PlayerField";

const AddPlayer = () => {
    const [playerName, setPlayerName] = useState("");
    const [playerId, setPlayerId] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryCodes, setCountryCodes] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        setCountryCodes(getCodeList());
    }, []);

    const addPlayer = async (playerData) => {
        try {
            const response = await axios.post("/api/add-player", playerData);
            setSuccessMessage(response.data["message"]);
        } catch (error) {
            setErrorMessage(error.response.data["message"]);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (playerName && playerId && countryCode) {
            addPlayer({
                player: playerName,
                chadsoft_id: playerId,
                country: countryCode
            });
        } else {
            setErrorMessage("All fields are required");
        }
    };
    return (
        <Layout title="Add Player">
            <ButtonGroup
                className="upload-form"
                component="form"
                onSubmit={handleSubmit}
            >
                <Box marginLeft={1} marginRight={1}>
                    <PlayerField
                        label="Player Name"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                    />
                </Box>
                <Box marginLeft={1} marginRight={1}>
                    <PlayerField
                        label="Player ID"
                        value={playerId}
                        onChange={(e) => setPlayerId(e.target.value)}
                    />
                </Box>
                <Box marginLeft={1} marginRight={1}>
                    <CountryField
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        countryCodes={countryCodes}
                    />
                </Box>
                <IconButton type="submit">
                    <UploadIcon/>
                </IconButton>
            </ButtonGroup>
            {successMessage &&
                <Message
                    severity="success"
                    title="Success"
                    message={successMessage}
                    onClose={() => setSuccessMessage("")}
                />
            }
            {errorMessage &&
                <Message
                    severity="error"
                    title="Error"
                    message={errorMessage}
                    onClose={() => setErrorMessage("")}
                />
            }
        </Layout>
    );
};

export default AddPlayer;