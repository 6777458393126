import React from "react";
import * as characters from "../images/characters";
import {
    Eighth,
    Eleventh,
    Fifth,
    First,
    Fourth,
    Ninth,
    Second,
    Seventh,
    Sixth,
    Tenth,
    Third,
    Twelfth
} from "../images/numbers";

export const getCharacterName = (character) => {
    if (!character) return null;
    if (character.includes("Mii")) {
        let size = character.includes("Large") ? "Large" : character.includes("Medium") ? "Medium" : "Small";
        let gender = character.includes("Male") ? "Male" : "Female";
        return `${size}${gender}Mii`;
    } else {
        return character.replaceAll(" ", "").replaceAll(".", "");
    }
}

export const getCharacterIcon = (character) => {
    if (!character) return null;
    character = character.replaceAll("Small", "").replaceAll("Medium", "").replaceAll("Large", "");
    return character ? characters[`${character}Icon`] : null;
}

const rankImages = {
    1: First,
    2: Second,
    3: Third,
    4: Fourth,
    5: Fifth,
    6: Sixth,
    7: Seventh,
    8: Eighth,
    9: Ninth,
    10: Tenth,
    11: Eleventh,
    12: Twelfth
};

export const getRankImage = (rank) => {
    const imageSrc = rankImages[rank];
    if (imageSrc) {
        return <img style={{height: "20px"}} src={imageSrc} alt={rank.toString()}/>;
    }
    return rank;
};