import * as FunkyKong from "../vehicles/Funky Kong";

export default {
    "Standard Kart L": FunkyKong.StandardKartL,
    "Standard Bike L": FunkyKong.StandardBikeL,
    "Offroader": FunkyKong.Offroader,
    "Flame Runner": FunkyKong.FlameRunner,
    "Wario Bike": FunkyKong.WarioBike,
    "Shooting Star": FunkyKong.ShootingStar,
    "Spear": FunkyKong.Spear,
    "Jetsetter": FunkyKong.Jetsetter,
    "Phantom": FunkyKong.Phantom,
    "Piranha Prowler": FunkyKong.PiranhaProwler,
    "Flame Flyer": FunkyKong.FlameFlyer,
    "Honeycoupe": FunkyKong.Honeycoupe
};