import * as BabyDaisy from "../vehicles/Baby Daisy";

export default {
    "Standard Kart S": BabyDaisy.StandardKartS,
    "Booster Seat": BabyDaisy.BoosterSeat,
    "Mini Beast": BabyDaisy.MiniBeast,
    "Cheep Charger": BabyDaisy.CheepCharger,
    "Tiny Titan": BabyDaisy.TinyTitan,
    "Blue Falcon": BabyDaisy.BlueFalcon,
    "Standard Bike S": BabyDaisy.StandardBikeS,
    "Bullet Bike": BabyDaisy.BulletBike,
    "Bit Bike": BabyDaisy.BitBike,
    "Magikruiser": BabyDaisy.Magikruiser,
    "Jet Bubble": BabyDaisy.JetBubble,
    "Quacker": BabyDaisy.Quacker
}