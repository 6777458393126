import * as images from "../../../images/nintendo_tracks";
import * as imagesSmall from "../../../images/nintendo_tracks_small";

const tracks = [
    {name: "Luigi Circuit", image: images.lc},
    {name: "Moo Moo Meadows", image: images.mmm},
    {name: "Mushroom Gorge", image: images.mg},
    {name: "Toad's Factory", image: images.tf},
    {name: "Mario Circuit", image: images.mc},
    {name: "Coconut Mall", image: images.cm},
    {name: "DK Summit", image: images.dks},
    {name: "Wario's Gold Mine", image: images.wgm},
    {name: "Daisy Circuit", image: images.dc},
    {name: "Koopa Cape", image: images.kc},
    {name: "Maple Treeway", image: images.mt},
    {name: "Grumble Volcano", image: images.gv},
    {name: "Dry Dry Ruins", image: images.ddr},
    {name: "Moonview Highway", image: images.mh},
    {name: "Bowser's Castle", image: images.bc},
    {name: "Rainbow Road", image: images.rr},
    {name: "GCN Peach Beach", image: images.rpb},
    {name: "DS Yoshi Falls", image: images.ryf},
    {name: "SNES Ghost Valley 2", image: images.rgv2},
    {name: "N64 Mario Raceway", image: images.rmr},
    {name: "N64 Sherbet Land", image: images.rsl},
    {name: "GBA Shy Guy Beach", image: images.rsgb},
    {name: "DS Delfino Square", image: images.rds},
    {name: "GCN Waluigi Stadium", image: images.rws},
    {name: "DS Desert Hills", image: images.rdh},
    {name: "GBA Bowser Castle 3", image: images.rbc3},
    {name: "N64 DK's Jungle Parkway", image: images.rdkjp},
    {name: "GCN Mario Circuit", image: images.rmc},
    {name: "SNES Mario Circuit 3", image: images.rmc3},
    {name: "DS Peach Gardens", image: images.rpg},
    {name: "GCN DK Mountain", image: images.rdkm},
    {name: "N64 Bowser's Castle", image: images.rbc}
];

const tracksSmall = [
    {name: "Luigi Circuit", image: imagesSmall.lc},
    {name: "Moo Moo Meadows", image: imagesSmall.mmm},
    {name: "Mushroom Gorge", image: imagesSmall.mg},
    {name: "Toad's Factory", image: imagesSmall.tf},
    {name: "Mario Circuit", image: imagesSmall.mc},
    {name: "Coconut Mall", image: imagesSmall.cm},
    {name: "DK Summit", image: imagesSmall.dks},
    {name: "Wario's Gold Mine", image: imagesSmall.wgm},
    {name: "Daisy Circuit", image: imagesSmall.dc},
    {name: "Koopa Cape", image: imagesSmall.kc},
    {name: "Maple Treeway", image: imagesSmall.mt},
    {name: "Grumble Volcano", image: imagesSmall.gv},
    {name: "Dry Dry Ruins", image: imagesSmall.ddr},
    {name: "Moonview Highway", image: imagesSmall.mh},
    {name: "Bowser's Castle", image: imagesSmall.bc},
    {name: "Rainbow Road", image: imagesSmall.rr},
    {name: "GCN Peach Beach", image: imagesSmall.rpb},
    {name: "DS Yoshi Falls", image: imagesSmall.ryf},
    {name: "SNES Ghost Valley 2", image: imagesSmall.rgv2},
    {name: "N64 Mario Raceway", image: imagesSmall.rmr},
    {name: "N64 Sherbet Land", image: imagesSmall.rsl},
    {name: "GBA Shy Guy Beach", image: imagesSmall.rsgb},
    {name: "DS Delfino Square", image: imagesSmall.rds},
    {name: "GCN Waluigi Stadium", image: imagesSmall.rws},
    {name: "DS Desert Hills", image: imagesSmall.rdh},
    {name: "GBA Bowser Castle 3", image: imagesSmall.rbc3},
    {name: "N64 DK's Jungle Parkway", image: imagesSmall.rdkjp},
    {name: "GCN Mario Circuit", image: imagesSmall.rmc},
    {name: "SNES Mario Circuit 3", image: imagesSmall.rmc3},
    {name: "DS Peach Gardens", image: imagesSmall.rpg},
    {name: "GCN DK Mountain", image: imagesSmall.rdkm},
    {name: "N64 Bowser's Castle", image: imagesSmall.rbc}
];


export const trackImageMapping = new Map(tracks.map(track => [track.name, track.image]));
export const trackImageMappingSmall = new Map(tracksSmall.map(track => [track.name, track.image]));