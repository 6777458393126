const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );

}

export default TabPanel;