import {IconButton} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "./CupItem.css";

const CupItem = ({cup, isSelected, onCupClick}) => {
    return (<Grid className={isSelected ? "cup-item cup-item-selected" : "cup-item"} xs={6} md={3}>
            <IconButton onClick={onCupClick}>
                <img
                    src={cup.image}
                    alt={cup.name}
                />
            </IconButton>
        </Grid>);
};

export default CupItem;