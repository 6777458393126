import * as MediumFemaleMii from "../vehicles/Medium Female Mii";

export default {
    "Standard Kart M": MediumFemaleMii.StandardKartM,
    "Classic Dragster": MediumFemaleMii.ClassicDragster,
    "Wild Wing": MediumFemaleMii.WildWing,
    "Super Blooper": MediumFemaleMii.SuperBlooper,
    "Daytripper": MediumFemaleMii.Daytripper,
    "Sprinter": MediumFemaleMii.Sprinter,
    "Standard Bike M": MediumFemaleMii.StandardBikeM,
    "Mach Bike": MediumFemaleMii.MachBike,
    "Sugarscoot": MediumFemaleMii.Sugarscoot,
    "Zip Zip": MediumFemaleMii.ZipZip,
    "Sneakster": MediumFemaleMii.Sneakster,
    "Dolphin Dasher": MediumFemaleMii.DolphinDasher
}