import * as LargeMaleMii from "../vehicles/Large Male Mii";

export default {
    "Standard Kart L": LargeMaleMii.StandardKartL,
    "Standard Bike L": LargeMaleMii.StandardBikeL,
    "Offroader": LargeMaleMii.Offroader,
    "Flame Runner": LargeMaleMii.FlameRunner,
    "Wario Bike": LargeMaleMii.WarioBike,
    "Shooting Star": LargeMaleMii.ShootingStar,
    "Spear": LargeMaleMii.Spear,
    "Jetsetter": LargeMaleMii.Jetsetter,
    "Phantom": LargeMaleMii.Phantom,
    "Piranha Prowler": LargeMaleMii.PiranhaProwler,
    "Flame Flyer": LargeMaleMii.FlameFlyer,
    "Honeycoupe": LargeMaleMii.Honeycoupe
};