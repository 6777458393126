import axios from "axios";
import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import ResultDisplay from "./ResultDisplay";
import UploadForm from "./UploadForm";
import "./UploadGhost.css";

const UploadGhost = () => {
    const [ghostData, setGhostData] = useState();
    const [url, setUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (ghostData) {
            setIsLoading(false);
        }
    }, [ghostData]);

    const onSubmit = (event) => {
        event.preventDefault();
        setGhostData();
        setIsLoading(true);
        axios.post("/api/upload-ghost", {url: url})
            .then((response) => {
                if (response.data.hasOwnProperty("message")) {
                    alert(response.data.message);
                }
                setGhostData(response.data);
            })
            .catch((error) => {
                alert(error.response.data.message);
                setIsLoading(false);

            });
    }

    const onConfirm = (event) => {
        event.preventDefault();
        axios.post("/api/upload-confirmation", {url: url})
            .then((response) => {
                alert(response.data.message)
                setGhostData();
                setUrl("");
            })
            .catch((error) => {
                alert(error.response.data.message);
                setGhostData();
                setUrl("");
            });
    }

    return (
        <Layout title="Add Ghost">
            <UploadForm url={url} onUrlChange={setUrl} onSubmit={onSubmit} isLoading={isLoading}/>
            <ResultDisplay ghostData={ghostData} onConfirm={onConfirm}/>
        </Layout>)
}

export default UploadGhost;