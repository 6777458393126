import {ThemeProvider} from "@emotion/react";
import {Tab, Tabs} from "@mui/material";
import {useState} from "react";
import {yellowTheme} from "../../themes";
import AddVideo from "./AddVideo";
import TabPanel from "./TabPanel";

const AdminPanels = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    return (
        <ThemeProvider theme={yellowTheme}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary">
                <Tab
                    label="Add Video"
                    sx={{
                        color: "rgb(255, 255, 255) !important",
                        textTransform: "none"
                    }}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <AddVideo/>
            </TabPanel>
        </ThemeProvider>
    );
}

export default AdminPanels;
