import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import {Alert} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import axios from "axios";
import React from "react";
import {ghostRenderer} from "../CellRenderers";

const TrackGhostsGrid = ({data, category}) => {
    const isEditable = (params) => {
        const value = params.data["VIDEO"]
        return value === null || value === undefined || value === "";
    }

    const columnDefs = [
        {headerName: "Player", field: "PLAYER_NAME", flex: 1},
        {headerName: "Player ID", field: "PLAYER_ID", flex: 1, cellClass: "left-cell"},
        {headerName: "Time", field: "TIME", flex: 1},
        {headerName: "Ghost", field: "HREF", flex: 0.5, filter: false, cellRenderer: ghostRenderer},
        {headerName: "Video", field: "VIDEO", flex: 2, editable: isEditable, filter: false, cellClass: "left-cell"},
        {headerName: "Previous Video", field: "PREV", flex: 2, editable: false, filter: false, cellClass: "left-cell"
        }
    ]

    const defaultColDef = {
        cellClass: "center-cell",
        filter: "agTextColumnFilter",
        floatingFilter: true,
    };

    const onCellEditingStopped = (event) => {
        const newValue = event.data.VIDEO;
        const oldValue = event.oldValue;

        if (newValue !== oldValue) {
            if (!validateYouTubeUrl(newValue)) {
                alert("Invalid YouTube URL");
                event.node.setDataValue("VIDEO", oldValue);
            } else {
                axios.post("/api/update-video",
                    {
                        href: event.data["HREF"],
                        category: category,
                        video: newValue
                    })
                    .then((response) => {
                        alert(response.data.message);
                        event.node.setDataValue("PREV", oldValue);
                    })
            }
        }
    }

    const validateYouTubeUrl = (url) => {
        console.log("Validating URL:", url)
        if (url === null || url === undefined || url === "") {
            return true;
        } else {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            let match = url.match(regExp);
            return !!(match && match[2].length === 11);
        }
    }

    return (
        <>
            <Alert variant="outlined" severity="warning" sx={{marginTop: "32px"}}>
                Double click the video cell to edit the video link.
                The database will update IMMEDIATELY after leaving the cell.
                Please only add YouTube links.
            </Alert>
            <div className="ag-theme-quartz-dark leaderboard-grid"
                 style={{marginTop: "32px", height: "66vh", width: "85vw"}}>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={data}
                    onCellEditingStopped={onCellEditingStopped}
                />
            </div>
        </>

    )
}

export default TrackGhostsGrid;