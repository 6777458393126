import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import React, {useEffect, useState} from "react";
import CupCategorySelector from "../CupCategorySelector";
import "./WorldwideTop10.css";
import TrackLeaderboardGrid from "../TrackLeaderboardGrid";

const WorldwideTop10 = () => {
    const [selectedCup, setSelectedCup] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState("Non-SC");
    const [trackData, setTrackData] = useState([]);

    useEffect(() => {
        axios.get("/api/ww-top-10", {
            params: {
                category: selectedCategory,
                cup: selectedCup
            }
        })
            .then((response) => {
                setTrackData(response.data);
            })
            .catch((error) => {
                console.log("Error occurred")
            });
    }, [selectedCategory, selectedCup]);

    return (
        <Grid className="worldwide-top-10" gap={2}>
            <CupCategorySelector
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedCup={selectedCup}
                setSelectedCup={setSelectedCup}
            />
            <TrackLeaderboardGrid data={trackData}/>
        </Grid>
    )
}

export default WorldwideTop10;