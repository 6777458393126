import * as Rosalina from "../vehicles/Rosalina";

export default {
    "Standard Kart L": Rosalina.StandardKartL,
    "Standard Bike L": Rosalina.StandardBikeL,
    "Offroader": Rosalina.Offroader,
    "Flame Runner": Rosalina.FlameRunner,
    "Wario Bike": Rosalina.WarioBike,
    "Shooting Star": Rosalina.ShootingStar,
    "Spear": Rosalina.Spear,
    "Jetsetter": Rosalina.Jetsetter,
    "Phantom": Rosalina.Phantom,
    "Piranha Prowler": Rosalina.PiranhaProwler,
    "Flame Flyer": Rosalina.FlameFlyer,
    "Honeycoupe": Rosalina.Honeycoupe
};