import * as Waluigi from "../vehicles/Waluigi";

export default {
    "Standard Kart L": Waluigi.StandardKartL,
    "Standard Bike L": Waluigi.StandardBikeL,
    "Offroader": Waluigi.Offroader,
    "Flame Runner": Waluigi.FlameRunner,
    "Wario Bike": Waluigi.WarioBike,
    "Shooting Star": Waluigi.ShootingStar,
    "Spear": Waluigi.Spear,
    "Jetsetter": Waluigi.Jetsetter,
    "Phantom": Waluigi.Phantom,
    "Piranha Prowler": Waluigi.PiranhaProwler,
    "Flame Flyer": Waluigi.FlameFlyer,
    "Honeycoupe": Waluigi.Honeycoupe
};