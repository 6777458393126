import UploadIcon from "@mui/icons-material/Upload";
import LoadingButton from "@mui/lab/LoadingButton";
import {ButtonGroup, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import "./UploadGhost.css";

const UploadForm = ({url, onUrlChange, onSubmit, isLoading}) => {
    const FONT_SIZE = 9;
    const DEFAULT_INPUT_WIDTH = 200;
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

    useEffect(() => {
        setInputWidth(Math.max(DEFAULT_INPUT_WIDTH, (url.length + 1) * FONT_SIZE))
    }, [url])

    return (
        <ButtonGroup
            className="upload-form"
            component="form"
            onSubmit={onSubmit}
        >
            <TextField
                variant="outlined"
                label="Chadsoft URL"
                required
                className="url-input"
                value={url}
                onChange={(e) => onUrlChange(e.target.value)}
                inputProps={{
                    style: {width: `${inputWidth}px`, textAlign: "center"},
                }}
                size="small"
            />
            <LoadingButton loading={isLoading} type="submit" className="loading-button">
                {!isLoading && <UploadIcon fontSize="small" color="disabled"/>}
            </LoadingButton>
        </ButtonGroup>
    )
}

export default UploadForm;