import * as DryBowser from "../vehicles/Dry Bowser";

export default {
    "Standard Kart L": DryBowser.StandardKartL,
    "Standard Bike L": DryBowser.StandardBikeL,
    "Offroader": DryBowser.Offroader,
    "Flame Runner": DryBowser.FlameRunner,
    "Wario Bike": DryBowser.WarioBike,
    "Shooting Star": DryBowser.ShootingStar,
    "Spear": DryBowser.Spear,
    "Jetsetter": DryBowser.Jetsetter,
    "Phantom": DryBowser.Phantom,
    "Piranha Prowler": DryBowser.PiranhaProwler,
    "Flame Flyer": DryBowser.FlameFlyer,
    "Honeycoupe": DryBowser.Honeycoupe
};