import * as Bowser from "../vehicles/Bowser";

export default {
    "Standard Kart L": Bowser.StandardKartL,
    "Standard Bike L": Bowser.StandardBikeL,
    "Offroader": Bowser.Offroader,
    "Flame Runner": Bowser.FlameRunner,
    "Wario Bike": Bowser.WarioBike,
    "Shooting Star": Bowser.ShootingStar,
    "Spear": Bowser.Spear,
    "Jetsetter": Bowser.Jetsetter,
    "Phantom": Bowser.Phantom,
    "Piranha Prowler": Bowser.PiranhaProwler,
    "Flame Flyer": Bowser.FlameFlyer,
    "Honeycoupe": Bowser.Honeycoupe
};