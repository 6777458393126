import * as Toadette from "../vehicles/Toadette";

export default {
    "Standard Kart S": Toadette.StandardKartS,
    "Booster Seat": Toadette.BoosterSeat,
    "Mini Beast": Toadette.MiniBeast,
    "Cheep Charger": Toadette.CheepCharger,
    "Tiny Titan": Toadette.TinyTitan,
    "Blue Falcon": Toadette.BlueFalcon,
    "Standard Bike S": Toadette.StandardBikeS,
    "Bullet Bike": Toadette.BulletBike,
    "Bit Bike": Toadette.BitBike,
    "Magikruiser": Toadette.Magikruiser,
    "Jet Bubble": Toadette.JetBubble,
    "Quacker": Toadette.Quacker
}