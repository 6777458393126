import * as MediumMaleMii from "../vehicles/Medium Male Mii";

export default {
    "Standard Kart M": MediumMaleMii.StandardKartM,
    "Classic Dragster": MediumMaleMii.ClassicDragster,
    "Wild Wing": MediumMaleMii.WildWing,
    "Super Blooper": MediumMaleMii.SuperBlooper,
    "Daytripper": MediumMaleMii.Daytripper,
    "Sprinter": MediumMaleMii.Sprinter,
    "Standard Bike M": MediumMaleMii.StandardBikeM,
    "Mach Bike": MediumMaleMii.MachBike,
    "Sugarscoot": MediumMaleMii.Sugarscoot,
    "Zip Zip": MediumMaleMii.ZipZip,
    "Sneakster": MediumMaleMii.Sneakster,
    "Dolphin Dasher": MediumMaleMii.DolphinDasher
}