const getColour = (position) => {
    switch (position) {
        case 1:
            return "gold"
        case 2:
            return "silver"
        case 3:
            return "bronze"
        default:
            return "white"
    }
}

const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
};

export {getColour};
export {groupBy};
