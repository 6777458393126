import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {Card, Grid, Typography} from "@mui/material";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import * as characters from "../../../../images/characters";
import * as controllers from "../../../../images/controllers";
import {shrooms} from "../../../../images/shrooms";
import {trackImageMapping} from "../../../TrackLeaderboard/TrackImage/trackMapping";
import "../../../TrackLeaderboard/TrackImage/TrackImage.css";
import {getCharacterIcon, getCharacterName} from "../../../utils";

const GhostTable = ({ghost}) => {
    const {
        PLAYER,
        PLAYER_ID,
        SHORTCUT,
        GLITCH,
        CHARACTER,
        VEHICLE,
        CONTROLLER,
        COUNTRY,
        TRACK,
        LAP_1,
        LAP_2,
        LAP_3,
        TIME,
        EXACT_TIME,
        LAP_1_SHROOMS,
        LAP_2_SHROOMS,
        LAP_3_SHROOMS
    } = ghost;
    const ghostCharacter = getCharacterName(CHARACTER);
    const character = characters[ghostCharacter];
    const characterIcon = getCharacterIcon(ghostCharacter);
    const vehicleImage = character ? character[VEHICLE] : "";
    const controller = controllers[CONTROLLER.replaceAll(" ", "")];
    const country = COUNTRY !== "None" ? <ReactCountryFlag countryCode={COUNTRY} svg className="country-flag"/> : null;
    const shroomsLap1 = LAP_1_SHROOMS ? shrooms[LAP_1_SHROOMS] : null;
    const shroomsLap2 = LAP_2_SHROOMS ? shrooms[LAP_2_SHROOMS] : null;
    const shroomsLap3 = LAP_3_SHROOMS ? shrooms[LAP_3_SHROOMS] : null;

    const renderIcon = (value) => {
        return value === "Y" ? <DoneIcon fontSize="small" color="success"/> :
            <CloseIcon fontSize="small" color="error"/>
    }

    const renderLap = (lap, shrooms) => (
        <Grid display="flex" alignItems="center">
            <Typography variant="body1">{lap}</Typography>
            {shrooms ? <img src={shrooms} alt={lap} className="shrooms-image"/> : null}
        </Grid>
    );

    return (
        <Card className="ghost-card">
            <img className="track-image" src={trackImageMapping.get(TRACK)} alt={TRACK}/>
            <div className="images-container">
                <img src={ghost["MII_DATA"]} alt="Mii" className="mii-image"/>
                <img src={characterIcon} alt={CHARACTER} className="character-image"/>
                <img src={vehicleImage} alt={VEHICLE} className="vehicle-image"/>
                <img src={controller} alt={CONTROLLER} className="controller-image"/>
                {country}
            </div>
            <Grid container xs={8} gap={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className="field-name" variant="body1">Mii Name</Typography>
                        <Typography className="field-name" variant="body1">Player ID</Typography>
                        <Typography className="field-name" variant="body1">Shortcut</Typography>
                        <Typography className="field-name" variant="body1">Glitch</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">{PLAYER}</Typography>
                        <Typography variant="body1">{PLAYER_ID}</Typography>
                        <Typography variant="body1">{renderIcon(SHORTCUT)}</Typography>
                        <Typography variant="body1">{renderIcon(GLITCH)}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className="field-name" variant="body1">Lap 1</Typography>
                        <Typography className="field-name" variant="body1">Lap 2</Typography>
                        <Typography className="field-name" variant="body1">Lap 3</Typography>
                        <Typography className="field-name" variant="body1">Total Time</Typography>
                        <br/>
                        <Typography className="field-name" variant="body1">Exact Time</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {renderLap(LAP_1, shroomsLap1)}
                        {renderLap(LAP_2, shroomsLap2)}
                        {renderLap(LAP_3, shroomsLap3)}
                        <Typography variant="body1">{TIME}</Typography>
                        <br/>
                        <Typography variant="body1">{EXACT_TIME}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default GhostTable;