import * as LargeFemaleMii from "../vehicles/Large Female Mii";

export default {
    "Standard Kart L": LargeFemaleMii.StandardKartL,
    "Standard Bike L": LargeFemaleMii.StandardBikeL,
    "Offroader": LargeFemaleMii.Offroader,
    "Flame Runner": LargeFemaleMii.FlameRunner,
    "Wario Bike": LargeFemaleMii.WarioBike,
    "Shooting Star": LargeFemaleMii.ShootingStar,
    "Spear": LargeFemaleMii.Spear,
    "Jetsetter": LargeFemaleMii.Jetsetter,
    "Phantom": LargeFemaleMii.Phantom,
    "Piranha Prowler": LargeFemaleMii.PiranhaProwler,
    "Flame Flyer": LargeFemaleMii.FlameFlyer,
    "Honeycoupe": LargeFemaleMii.Honeycoupe
};