import * as Yoshi from "../vehicles/Yoshi";

export default {
    "Standard Kart M": Yoshi.StandardKartM,
    "Classic Dragster": Yoshi.ClassicDragster,
    "Wild Wing": Yoshi.WildWing,
    "Super Blooper": Yoshi.SuperBlooper,
    "Daytripper": Yoshi.Daytripper,
    "Sprinter": Yoshi.Sprinter,
    "Standard Bike M": Yoshi.StandardBikeM,
    "Mach Bike": Yoshi.MachBike,
    "Sugarscoot": Yoshi.Sugarscoot,
    "Zip Zip": Yoshi.ZipZip,
    "Sneakster": Yoshi.Sneakster,
    "Dolphin Dasher": Yoshi.DolphinDasher
}