import {ThemeProvider} from "@emotion/react";
import {Box, Button, TextField} from "@mui/material";
import axios from "axios";
import React, {useState} from "react";
import {darkTheme} from "../../themes";
import useToken from "./Token";

const Login = () => {
    const {setToken} = useToken();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post("/api/login", {
            username: username,
            password: password
        }).then((response) => {
            if (response.status === 200) {
                setToken(response.data["access_token"]);
                window.location.href = "/admin";
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                alert("Invalid username or password");
            } else if (error.response.status === 400) {
                alert("Missing username or password");
            }
            setUsername("");
            setPassword("");
        });

    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Box component="form" className="center-box" onSubmit={handleSubmit} gap={1}>
                <TextField
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    size="small"
                />
                <TextField
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                />
                <Button type="submit" size="small" variant="contained">Login</Button>
            </Box>
        </ThemeProvider>
    )
}

export default Login;