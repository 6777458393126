import * as KingBoo from "../vehicles/King Boo";

export default {
    "Standard Kart L": KingBoo.StandardKartL,
    "Standard Bike L": KingBoo.StandardBikeL,
    "Offroader": KingBoo.Offroader,
    "Flame Runner": KingBoo.FlameRunner,
    "Wario Bike": KingBoo.WarioBike,
    "Shooting Star": KingBoo.ShootingStar,
    "Spear": KingBoo.Spear,
    "Jetsetter": KingBoo.Jetsetter,
    "Phantom": KingBoo.Phantom,
    "Piranha Prowler": KingBoo.PiranhaProwler,
    "Flame Flyer": KingBoo.FlameFlyer,
    "Honeycoupe": KingBoo.Honeycoupe
};