import YoutubeIcon from "@mui/icons-material/YouTube";
import {IconButton} from "@mui/material";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import boo from "../images/boo.webp";
import * as characters from "../images/characters";
import * as controllers from "../images/controllers";
import {trackImageMappingSmall} from "./TrackLeaderboard/TrackImage/trackMapping";
import {getCharacterIcon, getCharacterName, getRankImage} from "./utils";

const trackRenderer = (params) => {
    if (params.value) {
        return <img
            src={trackImageMappingSmall.get(params.value)}
            alt={params.value}
            style={{height: "100%"}}
        />
    }
}

const positionRenderer = (params) => {
    return getRankImage(params.value);
}

const characterRenderer = (params) => {
    const characterName = getCharacterName(params.value);
    if (params.value) {
        return <img
            src={getCharacterIcon(characterName)}
            alt={params.value}
            style={{height: "100%"}}
        />
    }
}

const vehicleRenderer = (params) => {
    const characterName = getCharacterName(params.data["CHARACTER"]);
    const character = characters[characterName];
    if (params.value) {
        return <img
            src={character[params.value]}
            alt={params.value}
            style={{height: "100%"}}
        />
    }
}

const controllerRenderer = (params) => {
    if (params.value) {
        return <img
            src={controllers[params.value.replaceAll(" ", "")]}
            alt={params.value}
            style={{height: "100%"}}
        />
    }
}

const ghostRenderer = (params) => {
    if (params.value) {
        return <a href={params.value} target="_blank" rel="noreferrer">
            <img
                style={{verticalAlign: "middle", width: "20px"}}
                src={boo}
                alt="Link to ghost"
            />
        </a>
    }
    return "";
};

const videoRenderer = (params) => {
    if (params.value) {
        return <IconButton
            sx={{padding: 0}}
            href={params.value} target="_blank" rel="noreferrer"
        >
            <YoutubeIcon
                fontSize="small"
                sx={{
                    color: "red",
                    backgroundColor: "white",
                    borderRadius: "50%"
                }}
            />
        </IconButton>
    }
}

const countryRenderer = (params) => {
    if (params.value) {
        return <ReactCountryFlag countryCode={params.value} svg style={{fontSize: "2em"}}/>
    }

}
export {
    trackRenderer,
    positionRenderer,
    characterRenderer,
    vehicleRenderer,
    controllerRenderer,
    ghostRenderer,
    videoRenderer,
    countryRenderer
};