import {Box} from "@mui/material";
import Layout from "../Upload/Layout";
import "./Donate.css";

const Donate = () => {
    const url = `https://www.paypal.com/donate/?hosted_button_id=${process.env.REACT_APP_HOSTED_BUTTON_ID}`;
    return (
        <Layout title="Donate">
            <Box
                className="donate-box"
                width={{xs: "100%", sm: "80%", md: "60%", lg: "40%"}}
            >
                This site is free to use and will always be ad-free.
                Unfortunately, maintaining this site is not free.
                The site is hosted on a server which costs a decent amount of money to keep running.
                It is also maintained by a single person who has spent countless hours working on it.
                <br/>
                <br/>
                Donations are greatly appreciated and help keep the site running. Donations are used to pay for server
                costs and to help support the developer.
                <br/>
                <br/>
                You can donate using the button below. Donations are processed through PayPal.
                <br/>
                <br/>
                <strong>Thank you for your support!</strong>
                <br/>
                <a target="_blank" rel="noreferrer" href={url}>
                    <img
                        src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                        alt="Donate with PayPal"
                    />
                </a>
            </Box>
        </Layout>
    );
}

export default Donate;