import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import {Grid} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {countryRenderer, positionRenderer} from "../CellRenderers";
import InfoModal from "../TrackLeaderboard/PlayerRow/InfoModal";
import TrackImage from "../TrackLeaderboard/TrackImage";
import {groupBy} from "../TrackLeaderboard/utils";
import "./TrackLeaderboardGrid.css";
import PlayerAlert from "./PlayerAlert";

const TrackLeaderboardGrid = ({data}) => {
    const [openInfo, setOpenInfo] = useState(false);
    const [ghostData, setGhostData] = useState({});
    const [RANK, setRANK] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const groupedData = groupBy(data, "TRACK_NAME");
    const [bannedPlayers, setBannedPlayers] = useState([]);

    useEffect(() => {
        axios.get("/api/get-banned-players")
            .then((response) => setBannedPlayers(response.data))
    }, []);

    const defaultColDef = {
        cellClass: "center-cell"
    };

    const rowClassRules = {
        "first": (params) => params.data["RANK"] === 1,
        "second": (params) => params.data["RANK"] === 2,
        "third": (params) => params.data["RANK"] === 3
    }

    const handleCloseInfo = () => {
        setOpenInfo(false);
    }

    const handleRowClick = (params) => {
        if (bannedPlayers.includes(params.data["PLAYER_ID"])) {
            setShowAlert(true);
            return;
        }
        axios.get("/api/get-ghost-info", {
            params: {
                player_id: params.data["PLAYER_ID"],
                track_id: params.data["TRACK_ID"],
                time: params.data["TIME"]
            }
        })
            .then((response) => {
                setGhostData(response.data[0]);
                setOpenInfo(true);
                setRANK(params.data["RANK"]);
            })
    }
    return (
        <Grid container className="center-grid" direction="column">
            {showAlert && <PlayerAlert onClose={() => setShowAlert(false)}/>}
            <Grid className="center-grid" gap={2} sx={{marginTop: "32px"}}>
                {Object.entries(groupedData).slice(0, 4).map(([trackName, trackData], index) => {
                    const columnDefs = [
                        {headerName: "", field: "RANK", flex: 0.5, cellRenderer: positionRenderer},
                        {headerName: "", field: "COUNTRY", flex: 0.5, cellRenderer: countryRenderer},
                        {headerName: "", field: "PLAYER", flex: 1, cellClass: "left-cell"},
                        {headerName: "", field: "TIME", flex: 1},
                    ];

                    return (
                        <Grid key={index} item container direction="column" className="center-grid">
                            <TrackImage trackName={trackName}/>
                            <div className="ag-theme-quartz-dark leaderboard-grid">
                                <AgGridReact
                                    domLayout="autoHeight"
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    rowData={trackData}
                                    headerHeight={0}
                                    onRowClicked={(params) => handleRowClick(params)}
                                    rowClassRules={rowClassRules}
                                />
                            </div>
                        </Grid>
                    );
                })
                }
            </Grid>
            {openInfo && <InfoModal open={openInfo} handleClose={handleCloseInfo} ghostData={ghostData} rank={RANK}/>}
        </Grid>

    );
};

export default TrackLeaderboardGrid;