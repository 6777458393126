import * as BabyLuigi from "../vehicles/Baby Luigi";

export default {
    "Standard Kart S": BabyLuigi.StandardKartS,
    "Booster Seat": BabyLuigi.BoosterSeat,
    "Mini Beast": BabyLuigi.MiniBeast,
    "Cheep Charger": BabyLuigi.CheepCharger,
    "Tiny Titan": BabyLuigi.TinyTitan,
    "Blue Falcon": BabyLuigi.BlueFalcon,
    "Standard Bike S": BabyLuigi.StandardBikeS,
    "Bullet Bike": BabyLuigi.BulletBike,
    "Bit Bike": BabyLuigi.BitBike,
    "Magikruiser": BabyLuigi.Magikruiser,
    "Jet Bubble": BabyLuigi.JetBubble,
    "Quacker": BabyLuigi.Quacker
}