import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {Card, createTheme, Grid, Modal, ThemeProvider, Typography} from "@mui/material";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import boo from "../../../images/boo.webp";
import * as characters from "../../../images/characters";
import * as controllers from "../../../images/controllers";
import {shrooms} from "../../../images/shrooms";
import {getCharacterIcon, getCharacterName, getRankImage} from "../../utils";
import {trackImageMapping} from "../TrackImage/trackMapping";
import "./InfoModal.css";

const darkTheme = createTheme({
    palette: {mode: "dark"}
});

const InfoModal = ({open, handleClose, ghostData, rank}) => {
    const {
        CHADSOFT_ID,
        CHARACTER,
        COUNTRY,
        CONTROLLER,
        HREF,
        IS_GLITCH,
        IS_SHORTCUT,
        LAP_1,
        LAP_2,
        LAP_3,
        LAP_1_SHROOMS,
        LAP_2_SHROOMS,
        LAP_3_SHROOMS,
        MII_DATA,
        PLAYER,
        PLAYER_NAME,
        TIME,
        TRACK_NAME,
        VEHICLE,
        VIDEO
    } = ghostData;
    const ghostCharacter = getCharacterName(CHARACTER);
    const character = characters[ghostCharacter];
    const characterIcon = getCharacterIcon(ghostCharacter);
    const vehicleImage = character ? character[VEHICLE] : "";
    const controller = CONTROLLER ? controllers[CONTROLLER.replaceAll(" ", "")] : null;
    const country = COUNTRY !== "None" ? <ReactCountryFlag countryCode={COUNTRY} svg className="country-flag"/> : null;
    const shroomsLap1 = LAP_1_SHROOMS ? shrooms[LAP_1_SHROOMS] : null;
    const shroomsLap2 = LAP_2_SHROOMS ? shrooms[LAP_2_SHROOMS] : null;
    const shroomsLap3 = LAP_3_SHROOMS ? shrooms[LAP_3_SHROOMS] : null;
    const rankImage = getRankImage(rank);

    const renderIcon = (value) => {
        return value ? <DoneIcon fontSize="small" color="success"/> :
            <CloseIcon fontSize="small" color="error"/>
    }

    const renderLap = (lap, shrooms) => (
        <Grid display="flex" alignItems="center">
            <Typography variant="body1">{lap}</Typography>
            {shrooms ? <img src={shrooms} alt={lap} className="shrooms-image"/> : null}
        </Grid>
    );

    const isChadsoftID = /^[0-9A-Fa-f]{16}$/.test(CHADSOFT_ID);

    const getVideoID = (link) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*)(.*)/;
        const match = link.match(regExp);

        if (match) {
            let videoID = match[2];
            let timestamp = match[3];
            timestamp = timestamp.replace('&t=', '?start=');
            //  remove s at the end of timestamp if it exists
            if (timestamp.endsWith('s')) {
                timestamp = timestamp.slice(0, -1);
            }
            return videoID + timestamp;
        }
        return null;
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                open={open}
                onClose={handleClose}
                className="info-modal"
            >
                <Card className="ghost-card">
                    <img className="track-image" src={trackImageMapping.get(TRACK_NAME)} alt={TRACK_NAME}/>
                    <div className="images-container">
                        {MII_DATA ? <img src={MII_DATA} alt="Mii" className="mii-image"/> : null}
                        <img src={characterIcon} alt={CHARACTER} className="character-image"/>
                        <img src={vehicleImage} alt={VEHICLE} className="vehicle-image"/>
                        {CONTROLLER ? <img src={controller} alt={CONTROLLER} className="controller-image"/> : null}
                        {country}
                    </div>
                    <Grid item xs={8} gap={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography className="field-name" variant="body1">Player Name</Typography>
                                {isChadsoftID ?
                                    <Typography className="field-name" variant="body1">Player ID</Typography> : null}
                                <Typography className="field-name" variant="body1">Mii Name</Typography>
                                <Typography className="field-name" variant="body1">Shortcut</Typography>
                                <Typography className="field-name" variant="body1">Glitch</Typography>
                                {HREF ? <Typography className="field-name" variant="body1">Ghost</Typography> : null}
                                <Typography className="field-name" variant="body1">Rank</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">{PLAYER_NAME}</Typography>
                                {isChadsoftID ? <Typography variant="body1">{CHADSOFT_ID}</Typography> : null}
                                <Typography variant="body1">{PLAYER}</Typography>
                                <Typography variant="body1">{renderIcon(IS_SHORTCUT)}</Typography>
                                <Typography variant="body1">{renderIcon(IS_GLITCH)}</Typography>
                                {HREF ? <a href={HREF} target="_blank" rel="noreferrer">
                                    <img className="link-image" src={boo} alt="Link to ghost"/><br/>
                                </a> : null}
                                {rankImage}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {LAP_1 ? <Typography className="field-name" variant="body1">Lap 1</Typography> : null}
                                {LAP_2 ? <Typography className="field-name" variant="body1">Lap 2</Typography> : null}
                                {LAP_3 ? <Typography className="field-name" variant="body1">Lap 3</Typography> : null}
                                <Typography className="field-name" variant="body1">Total Time</Typography>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                                {renderLap(LAP_1, shroomsLap1)}
                                {renderLap(LAP_2, shroomsLap2)}
                                {renderLap(LAP_3, shroomsLap3)}
                                <Typography variant="body1">{TIME}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {VIDEO ? <iframe
                        width="360"
                        height="200"
                        src={`https://www.youtube.com/embed/${getVideoID(VIDEO)}`}
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    /> : null}

                </Card>
            </Modal>
        </ThemeProvider>
    );
};

export default InfoModal;