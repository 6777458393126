import * as SmallMaleMii from "../vehicles/Small Male Mii";

export default {
    "Standard Kart S": SmallMaleMii.StandardKartS,
    "Booster Seat": SmallMaleMii.BoosterSeat,
    "Mini Beast": SmallMaleMii.MiniBeast,
    "Cheep Charger": SmallMaleMii.CheepCharger,
    "Tiny Titan": SmallMaleMii.TinyTitan,
    "Blue Falcon": SmallMaleMii.BlueFalcon,
    "Standard Bike S": SmallMaleMii.StandardBikeS,
    "Bullet Bike": SmallMaleMii.BulletBike,
    "Bit Bike": SmallMaleMii.BitBike,
    "Magikruiser": SmallMaleMii.Magikruiser,
    "Jet Bubble": SmallMaleMii.JetBubble,
    "Quacker": SmallMaleMii.Quacker
}