import React from "react";

const CTGPTop10 = () => {
    return (
        <div>
            <h1>CTGP Top 10</h1>
        </div>
    )
}

export default CTGPTop10;