import {TextField} from "@mui/material";
import React from "react";

const PlayerField = ({label, value, onChange}) => {
    return (
        <TextField
            required
            size="small"
            label={label}
            value={value}
            onChange={onChange}
        />
    )
}

export default PlayerField;