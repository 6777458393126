import React from "react";

const RegionalTop10 = () => {
    return (
        <div>
            <h1>Coming soon...</h1>
        </div>
    )
}

export default RegionalTop10;