import * as Birdo from "../vehicles/Birdo";

export default {
    "Standard Kart M": Birdo.StandardKartM,
    "Classic Dragster": Birdo.ClassicDragster,
    "Wild Wing": Birdo.WildWing,
    "Super Blooper": Birdo.SuperBlooper,
    "Daytripper": Birdo.Daytripper,
    "Sprinter": Birdo.Sprinter,
    "Standard Bike M": Birdo.StandardBikeM,
    "Mach Bike": Birdo.MachBike,
    "Sugarscoot": Birdo.Sugarscoot,
    "Zip Zip": Birdo.ZipZip,
    "Sneakster": Birdo.Sneakster,
    "Dolphin Dasher": Birdo.DolphinDasher
}