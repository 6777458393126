import {Alert, Button} from "@mui/material";
import React from "react";
import GhostPreviewTable from "./GhostPreviewTable";

const ResultDisplay = ({ghostData, onConfirm}) => {
    const {isValid, existingData} = ghostData || {};

    const renderAlert = (message, severity) => (
        <Alert severity={severity} variant="outlined">
            {message}
        </Alert>
    );

    return (
        <>
            {isValid === "N" && renderAlert("Not a kart time!", "error")}
            {existingData && renderAlert("This ghost is already in the database!", "error")}
            {ghostData && <GhostPreviewTable ghostData={ghostData}/>}
            {/* TODO: Temporary, until all previous top 10s have been reuploaded to get their lap times etc?*/}
            {isValid === "Y" &&
            // {!existingData && isValid === "Y" &&
                <Button
                    className="confirm-button"
                    color="success"
                    variant="contained"
                    onClick={(e) => onConfirm(e)}
                >
                    Confirm
                </Button>}
        </>
    );
};

export default ResultDisplay;