import * as Toad from "../vehicles/Toad";

export default {
    "Standard Kart S": Toad.StandardKartS,
    "Booster Seat": Toad.BoosterSeat,
    "Mini Beast": Toad.MiniBeast,
    "Cheep Charger": Toad.CheepCharger,
    "Tiny Titan": Toad.TinyTitan,
    "Blue Falcon": Toad.BlueFalcon,
    "Standard Bike S": Toad.StandardBikeS,
    "Bullet Bike": Toad.BulletBike,
    "Bit Bike": Toad.BitBike,
    "Magikruiser": Toad.Magikruiser,
    "Jet Bubble": Toad.JetBubble,
    "Quacker": Toad.Quacker
}