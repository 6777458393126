import React from "react";

const PlayerMatchup = () => {
    return (
        <div>
            <h1>Coming soon...</h1>
        </div>
    )
}

export default PlayerMatchup;