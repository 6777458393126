import * as DiddyKong from "../vehicles/Diddy Kong";

export default {
    "Standard Kart M": DiddyKong.StandardKartM,
    "Classic Dragster": DiddyKong.ClassicDragster,
    "Wild Wing": DiddyKong.WildWing,
    "Super Blooper": DiddyKong.SuperBlooper,
    "Daytripper": DiddyKong.Daytripper,
    "Sprinter": DiddyKong.Sprinter,
    "Standard Bike M": DiddyKong.StandardBikeM,
    "Mach Bike": DiddyKong.MachBike,
    "Sugarscoot": DiddyKong.Sugarscoot,
    "Zip Zip": DiddyKong.ZipZip,
    "Sneakster": DiddyKong.Sneakster,
    "Dolphin Dasher": DiddyKong.DolphinDasher
}