import {AppBar, Box, Toolbar} from "@mui/material";
import axios from "axios";
import React, {useState} from "react";
import "./Header.css";
import useToken from "../Login/Token";
import CustomButton from "./CustomButton";
import MenuItemComponent from "./MenuItemComponent";

const Header = () => {
    const {token, removeToken} = useToken();
    const [anchorElLeaderboard, setAnchorElLeaderboard] = useState(null);
    const [anchorElPlayer, setAnchorElPlayer] = useState(null);
    const [anchorElUpload, setAnchorElUpload] = useState(null);

    const logout = () => {
        axios.post("/api/logout").then(() => {
            removeToken();
            window.location.href = "/";
        });
    }
    const handleClickLeaderboard = (event) => {
        setAnchorElLeaderboard(event.currentTarget);
    };

    const handleClickPlayer = (event) => {
        setAnchorElPlayer(event.currentTarget);
    };

    const handleClickUpload = (event) => {
        setAnchorElUpload(event.currentTarget);
    };

    const handleCloseLeaderboard = () => {
        setAnchorElLeaderboard(null);
    };

    const handleClosePlayer = () => {
        setAnchorElPlayer(null);
    };

    const handleCloseUpload = () => {
        setAnchorElUpload(null);
    };

    const leaderboardItems = [
        {link: "/worldwide-top-10", text: "Worldwide Top 10"},
        // TODO: Implement these pages
        // {link: "/regional-top-10", text: "Regional Top 10"},
        // {link: "/ctgp-top-10", text: "CTGP Top 10"}
    ];

    const playerItems = [
        {link: "/player-stats", text: "Player Stats"},
        // TODO: Implement these pages
        // {link: "/player-matchup", text: "Player Match-up"}
    ];

    const uploadItems = [
        {link: "/upload-ghost", text: "Upload New Ghost"},
        {link: "/add-new-player", text: "Add New Player"}
    ];

    const buttons = [
        {
            buttonText: "Leaderboards",
            ariaControls: "leaderboard-menu",
            ariaHasPopup: true,
            onClick: handleClickLeaderboard
        },
        {buttonText: "Players", ariaControls: "player-menu", ariaHasPopup: true, onClick: handleClickPlayer},
        {buttonText: "Upload", ariaControls: "upload-menu", ariaHasPopup: true, onClick: handleClickUpload},
        {buttonText: "Links", link: "/links"},
        {buttonText: "Donate", link: "/donate"},
        {buttonText: "Admin", link: "/admin", hidden: !token},
        {buttonText: "Logout", onClick: logout, hidden: !token}
    ];


    return (
        <AppBar position="static" className="header">
            <Toolbar>
                <CustomButton buttonText="MKW Kart Leaderboard" link="/"/>
                <Box flexGrow={1}/>
                <nav className="header-nav">
                    {buttons.map((button, index) => (
                        <CustomButton key={index} {...button} />
                    ))}
                    <MenuItemComponent
                        id="leaderboard-menu"
                        anchorEl={anchorElLeaderboard}
                        handleClose={handleCloseLeaderboard}
                        menuItems={leaderboardItems}
                    />
                    <MenuItemComponent
                        id="player-menu"
                        anchorEl={anchorElPlayer}
                        handleClose={handleClosePlayer}
                        menuItems={playerItems}
                    />
                    <MenuItemComponent
                        id="upload-menu"
                        anchorEl={anchorElUpload}
                        handleClose={handleCloseUpload}
                        menuItems={uploadItems}
                    />
                </nav>
            </Toolbar>
        </AppBar>
    )
}

export default Header;