import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, {useMemo} from "react";
import "./StatsGrid.css";
import {
    characterRenderer,
    controllerRenderer,
    ghostRenderer,
    positionRenderer,
    trackRenderer,
    vehicleRenderer,
    videoRenderer
} from "../CellRenderers";
import {Typography} from "@mui/material";


const StatsGrid = ({rowData, title}) => {
    const defaultColDef = {
        cellClass: "center-cell"
    }

    const columnDefs = useMemo(() => {
        return [
            {headerName: "", field: "TRACK", cellRenderer: trackRenderer, flex: 1.25},
            {headerName: "Position", field: "POSITION", cellRenderer: positionRenderer, flex: 0.5},
            {headerName: "Time", field: "TIME", flex: 0.5},
            {headerName: "Character", field: "CHARACTER", cellRenderer: characterRenderer, flex: 0.75},
            {headerName: "Vehicle", field: "VEHICLE", cellRenderer: vehicleRenderer, flex: 0.75},
            {headerName: "Controller", field: "CONTROLLER", cellRenderer: controllerRenderer, flex: 0.75},
            {headerName: "Ghost", field: "GHOST", cellRenderer: ghostRenderer, flex: 0.5},
            {headerName: "Video", field: "VIDEO", cellRenderer: videoRenderer, flex: 0.5}
        ]
    }, []);

    return (
        <div className="ag-theme-quartz-dark" style={{marginTop: "16px", width: "1000px", maxWidth: "1000px"}}>
            <Typography variant="h5" style={{textAlign: "center", marginBottom: "16px"}}>{title}</Typography>
            <AgGridReact
                domLayout='autoHeight'
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={rowData}
            />
        </div>
    );
}

export default StatsGrid;