import React, {useState} from "react";
import {Box, Button, ButtonGroup} from "@mui/material";
import "./CategorySelector.css";

const CategorySelector = ({selectedCategory, setSelectedCategory}) =>{

    return (
        <Box className="category-selector">
            <ButtonGroup>
                <Button onClick={() => setSelectedCategory("Non-SC")} className={selectedCategory === "Non-SC" ? "selected" : ""}>
                    Non-SC
                </Button>
                <Button onClick={() => setSelectedCategory("SC")} className={selectedCategory === "SC" ? "selected" : ""}>
                    SC
                </Button>
                <Button onClick={() => setSelectedCategory("Glitch")} className={selectedCategory === "Glitch" ? "selected" : ""}>
                    Glitch
                </Button>
            </ButtonGroup>
        </Box>
    )
}

export default CategorySelector;