import * as DryBones from "../vehicles/Dry Bones";

export default {
    "Standard Kart S": DryBones.StandardKartS,
    "Booster Seat": DryBones.BoosterSeat,
    "Mini Beast": DryBones.MiniBeast,
    "Cheep Charger": DryBones.CheepCharger,
    "Tiny Titan": DryBones.TinyTitan,
    "Blue Falcon": DryBones.BlueFalcon,
    "Standard Bike S": DryBones.StandardBikeS,
    "Bullet Bike": DryBones.BulletBike,
    "Bit Bike": DryBones.BitBike,
    "Magikruiser": DryBones.Magikruiser,
    "Jet Bubble": DryBones.JetBubble,
    "Quacker": DryBones.Quacker
}