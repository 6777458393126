import * as PrincessPeach from "../vehicles/Peach";

export default {
    "Standard Kart M": PrincessPeach.StandardKartM,
    "Classic Dragster": PrincessPeach.ClassicDragster,
    "Wild Wing": PrincessPeach.WildWing,
    "Super Blooper": PrincessPeach.SuperBlooper,
    "Daytripper": PrincessPeach.Daytripper,
    "Sprinter": PrincessPeach.Sprinter,
    "Standard Bike M": PrincessPeach.StandardBikeM,
    "Mach Bike": PrincessPeach.MachBike,
    "Sugarscoot": PrincessPeach.Sugarscoot,
    "Zip Zip": PrincessPeach.ZipZip,
    "Sneakster": PrincessPeach.Sneakster,
    "Dolphin Dasher": PrincessPeach.DolphinDasher
}