import Login from "../Login";
import useToken from "../Login/Token";
import AdminPanels from "./AdminPanels";

const Admin = () => {
    const {token} = useToken();
    const unauthorized = !token || token === "" || token === undefined;

    return (
        <>
            {unauthorized ? <Login/> : <AdminPanels/>}
        </>
    )
}

export default Admin;