import * as DonkeyKong from "../vehicles/Donkey Kong";

export default {
    "Standard Kart L": DonkeyKong.StandardKartL,
    "Standard Bike L": DonkeyKong.StandardBikeL,
    "Offroader": DonkeyKong.Offroader,
    "Flame Runner": DonkeyKong.FlameRunner,
    "Wario Bike": DonkeyKong.WarioBike,
    "Shooting Star": DonkeyKong.ShootingStar,
    "Spear": DonkeyKong.Spear,
    "Jetsetter": DonkeyKong.Jetsetter,
    "Phantom": DonkeyKong.Phantom,
    "Piranha Prowler": DonkeyKong.PiranhaProwler,
    "Flame Flyer": DonkeyKong.FlameFlyer,
    "Honeycoupe": DonkeyKong.Honeycoupe
};