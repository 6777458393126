import * as PrincessDaisy from "../vehicles/Daisy";

export default {
    "Standard Kart M": PrincessDaisy.StandardKartM,
    "Classic Dragster": PrincessDaisy.ClassicDragster,
    "Wild Wing": PrincessDaisy.WildWing,
    "Super Blooper": PrincessDaisy.SuperBlooper,
    "Daytripper": PrincessDaisy.Daytripper,
    "Sprinter": PrincessDaisy.Sprinter,
    "Standard Bike M": PrincessDaisy.StandardBikeM,
    "Mach Bike": PrincessDaisy.MachBike,
    "Sugarscoot": PrincessDaisy.Sugarscoot,
    "Zip Zip": PrincessDaisy.ZipZip,
    "Sneakster": PrincessDaisy.Sneakster,
    "Dolphin Dasher": PrincessDaisy.DolphinDasher
}