import {Box, ThemeProvider, Typography} from "@mui/material";
import {darkTheme} from "../../themes";

const Layout = ({title, children}) => (
    <ThemeProvider theme={darkTheme}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="20px" padding="16px">
            <Typography variant="h4">{title}</Typography>
            {children}
        </Box>
    </ThemeProvider>
);

export default Layout;