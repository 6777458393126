import React from "react";
import GhostTable from "./GhostTable";
import "./GhostPreviewTable.css";

const GhostPreviewTable = ({ghostData}) => {
    return (
        <div className="preview-container">
            {ghostData["newData"] && <GhostTable ghost={ghostData["newData"]}/>}
        </div>
    )
};

export default GhostPreviewTable;