import {Alert, Snackbar} from "@mui/material";
import React from "react";

const PlayerAlert = ({onClose}) => {
    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={true}
            autoHideDuration={4000}
            onClose={onClose}
            message="This player has been permanently excluded from the community due to inappropriate conduct with
            underage members. Their times remain on the leaderboard solely to retain accuracy."
        >
            <Alert severity="error" >
                This player has been permanently excluded from the community due to inappropriate conduct with
                underage members. Their times remain on the leaderboard solely to retain accuracy.
            </Alert>
        </Snackbar>
    )
}

export default PlayerAlert;