import {
    BananaCupIcon,
    FlowerCupIcon,
    LeafCupIcon,
    LightningCupIcon,
    MushroomCupIcon,
    ShellCupIcon,
    SpecialCupIcon,
    StarCupIcon
} from "../../../images/nintendo_cups"

const cupIcons = [
    {
        id: 0,
        name: "Mushroom Cup",
        image: MushroomCupIcon
    },
    {
        id: 1,
        name: "Flower Cup",
        image: FlowerCupIcon
    },
    {
        id: 2,
        name: "Star Cup",
        image: StarCupIcon
    },
    {
        id: 3,
        name: "Special Cup",
        image: SpecialCupIcon
    },
    {
        id: 4,
        name: "Shell Cup",
        image: ShellCupIcon
    },
    {
        id: 5,
        name: "Banana Cup",
        image: BananaCupIcon
    },
    {
        id: 6,
        name: "Leaf Cup",
        image: LeafCupIcon
    },
    {
        id: 7,
        name: "Lightning Cup",
        image: LightningCupIcon
    },
];

export default cupIcons;