import * as BowserJr from "../vehicles/Bowser Jr";

export default {
    "Standard Kart M": BowserJr.StandardKartM,
    "Classic Dragster": BowserJr.ClassicDragster,
    "Wild Wing": BowserJr.WildWing,
    "Super Blooper": BowserJr.SuperBlooper,
    "Daytripper": BowserJr.Daytripper,
    "Sprinter": BowserJr.Sprinter,
    "Standard Bike M": BowserJr.StandardBikeM,
    "Mach Bike": BowserJr.MachBike,
    "Sugarscoot": BowserJr.Sugarscoot,
    "Zip Zip": BowserJr.ZipZip,
    "Sneakster": BowserJr.Sneakster,
    "Dolphin Dasher": BowserJr.DolphinDasher
}