import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AddLinkIcon from "@mui/icons-material/AddLink";
import InsightsIcon from "@mui/icons-material/Insights";
import PublicIcon from "@mui/icons-material/Public";
import {Box, List, Typography} from "@mui/material";
import React from "react";
import "./Home.css";
import boo from "../../images/boo.webp";
import CustomListItem from "./CustomListItem";

const Home = () => {
    const listItems = [
        {to: "/worldwide-top-10", icon: PublicIcon, text: "Worldwide Top 10"},
        {to: "/player-stats", icon: InsightsIcon, text: "Player Stats"},
        {to: "/upload-ghost", icon: () => <img src={boo} alt="boo" className="boo"/>, text: "Upload Ghost"},
        {to: "/add-new-player", icon: AccessibilityNewIcon, text: "Add New Player"},
        {to: "/links", icon: AddLinkIcon, text: "Other Links"}
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <div className="home-container">
                <Typography variant="h4" className="heading">
                    Welcome to MKW Kart Leaderboards!
                </Typography>
                <List className="link-list" component="nav">
                    {listItems.map((item, index) => (
                        <CustomListItem key={index} {...item} />
                    ))}
                </List>
            </div>
        </Box>
    );
};


export default Home;