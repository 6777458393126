import * as SmallFemaleMii from "../vehicles/Small Female Mii";

export default {
    "Standard Kart S": SmallFemaleMii.StandardKartS,
    "Booster Seat": SmallFemaleMii.BoosterSeat,
    "Mini Beast": SmallFemaleMii.MiniBeast,
    "Cheep Charger": SmallFemaleMii.CheepCharger,
    "Tiny Titan": SmallFemaleMii.TinyTitan,
    "Blue Falcon": SmallFemaleMii.BlueFalcon,
    "Standard Bike S": SmallFemaleMii.StandardBikeS,
    "Bullet Bike": SmallFemaleMii.BulletBike,
    "Bit Bike": SmallFemaleMii.BitBike,
    "Magikruiser": SmallFemaleMii.Magikruiser,
    "Jet Bubble": SmallFemaleMii.JetBubble,
    "Quacker": SmallFemaleMii.Quacker
}