import * as Wario from "../vehicles/Wario";

export default {
    "Standard Kart L": Wario.StandardKartL,
    "Standard Bike L": Wario.StandardBikeL,
    "Offroader": Wario.Offroader,
    "Flame Runner": Wario.FlameRunner,
    "Wario Bike": Wario.WarioBike,
    "Shooting Star": Wario.ShootingStar,
    "Spear": Wario.Spear,
    "Jetsetter": Wario.Jetsetter,
    "Phantom": Wario.Phantom,
    "Piranha Prowler": Wario.PiranhaProwler,
    "Flame Flyer": Wario.FlameFlyer,
    "Honeycoupe": Wario.Honeycoupe
};