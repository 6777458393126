import React from "react";
import CategorySelector from "./CategorySelector/CategorySelector";
import CupGrid from "./CupGrid";
import Grid from "@mui/material/Unstable_Grid2";
import "./CupCategorySelector.css";

const CupCategorySelector = ({selectedCategory, setSelectedCategory, selectedCup, setSelectedCup}) => {
    return (
        <Grid className="cup-category-selector-container">
            <CategorySelector selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
            <CupGrid selectedCup={selectedCup} setSelectedCup={setSelectedCup}/>
        </Grid>
    )
}

export default CupCategorySelector;