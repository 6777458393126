import * as Mario from "../vehicles/Mario";

export default {
    "Standard Kart M": Mario.StandardKartM,
    "Classic Dragster": Mario.ClassicDragster,
    "Wild Wing": Mario.WildWing,
    "Super Blooper": Mario.SuperBlooper,
    "Daytripper": Mario.Daytripper,
    "Sprinter": Mario.Sprinter,
    "Standard Bike M": Mario.StandardBikeM,
    "Mach Bike": Mario.MachBike,
    "Sugarscoot": Mario.Sugarscoot,
    "Zip Zip": Mario.ZipZip,
    "Sneakster": Mario.Sneakster,
    "Dolphin Dasher": Mario.DolphinDasher
}