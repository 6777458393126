import StartIcon from '@mui/icons-material/Start';
import {Box, ListItem, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";

const CustomListItem = ({to, icon: Icon, text}) => {
    return (
        <ListItem component={Link} to={to} className="home-link">
            <Box marginRight={2}>
                <Icon/>
            </Box>
            <ListItemText primary={text}/>
            <Box marginLeft={2}>
                <StartIcon fontSize="small"/>
            </Box>
        </ListItem>
    );
};

export default CustomListItem;