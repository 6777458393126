import * as BabyMario from "../vehicles/Baby Mario";

export default {
    "Standard Kart S": BabyMario.StandardKartS,
    "Booster Seat": BabyMario.BoosterSeat,
    "Mini Beast": BabyMario.MiniBeast,
    "Cheep Charger": BabyMario.CheepCharger,
    "Tiny Titan": BabyMario.TinyTitan,
    "Blue Falcon": BabyMario.BlueFalcon,
    "Standard Bike S": BabyMario.StandardBikeS,
    "Bullet Bike": BabyMario.BulletBike,
    "Bit Bike": BabyMario.BitBike,
    "Magikruiser": BabyMario.Magikruiser,
    "Jet Bubble": BabyMario.JetBubble,
    "Quacker": BabyMario.Quacker
}