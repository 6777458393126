import {Menu, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";

const MenuItemComponent = ({id, anchorEl, handleClose, menuItems}) => (
    <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        transformOrigin={{vertical: "top", horizontal: "center"}}
    >
        {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={handleClose}>
                <Link to={item.link}>{item.text}</Link>
            </MenuItem>
        ))}
    </Menu>
);

export default MenuItemComponent;