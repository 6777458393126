import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

const CountryField = ({value, onChange, countryCodes}) => {
    return (
        <FormControl size="small">
            <InputLabel>Country Code</InputLabel>
            <Select
                required
                size="small"
                value={value}
                sx={{minWidth: 200}}
                onChange={onChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {Object.entries(countryCodes).map(([code, name]) => (
                    <MenuItem key={code} value={code.toUpperCase()}>
                        {`${name} (${code.toUpperCase()})`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default CountryField;