import {Button} from "@mui/material";
import {Link} from "react-router-dom";

const ButtonComponent = ({ariaControls, ariaHasPopup, onClick, buttonText, link, hidden}) => {
    return (
        <>
            {!hidden &&
                <Button
                    aria-controls={ariaControls}
                    aria-haspopup={ariaHasPopup}
                    onClick={onClick}
                    component={link ? Link : "button"}
                    to={link}
                    className="title-button"
                >             {buttonText}
                </Button>
            }
        </>
    )
}

export default ButtonComponent;