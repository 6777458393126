import ButtonComponent from "./ButtonComponent";

const CustomButton = ({buttonText, link, ariaControls, ariaHasPopup, onClick, hidden}) => {
    return (
        <ButtonComponent
            buttonText={buttonText}
            link={link}
            ariaControls={ariaControls}
            ariaHasPopup={ariaHasPopup}
            onClick={onClick}
            hidden={hidden}
        />
    );
};

export default CustomButton;