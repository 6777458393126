import {MenuItem, Select} from "@mui/material";
import axios from "axios";
import React, {useEffect, useMemo, useState} from "react";
import ReactCountryFlag from "react-country-flag";
import Layout from "../Upload/Layout";
import StatsGrid from "./StatsGrid";
import "./PlayerStats.css";

const PlayerStats = () => {
    const [playerName, setPlayerName] = useState("Select a player");
    const [playerData, setPlayerData] = useState([]);
    const [nonSCData, setNonSCData] = useState({});
    const [scData, setSCData] = useState({});
    const [glitchData, setGlitchData] = useState({});

    const countryFlag = (countryCode) => {
        return <ReactCountryFlag countryCode={countryCode} svg className="country-flag"/>;
    }

    useEffect(() => {
        axios.get("/api/get-players")
            .then((response) => {
                setPlayerData(response.data);
            });
    }, []);

    const menuItems = useMemo(() => {
        return playerData.map((player) => (
            player["CHADSOFT_ID"] !== "EE91F250E359EC6E" &&
            <MenuItem key={player["CHADSOFT_ID"]} value={player["CHADSOFT_ID"]}>
                {countryFlag(player["COUNTRY"])} {player["PLAYER_NAME"]} {player["CHADSOFT_ID"].length === 16 ? `(${player["CHADSOFT_ID"]})` : ""}
            </MenuItem>
        ));
    }, [playerData]);

    const handleChange = (event) => {
        setPlayerName(event.target.value);

        axios.get("/api/get-player-stats",
            {params: {player_id: event.target.value}})
            .then((response) => {
                setNonSCData(response.data["non_sc"]);
                setSCData(response.data["sc"]);
                setGlitchData(response.data["glitch"]);
            });
    }
    return (
        <Layout title="Player Stats">
            <Select
                sx={{marginTop: "20px"}}
                size="small"
                value={playerName}
                onChange={handleChange}
            >
                <MenuItem disabled value="Select a player">
                    <em>Select a player</em>
                </MenuItem>
                {playerData.length > 0 ? menuItems : null}
            </Select>
            {nonSCData.length > 0 ? <StatsGrid rowData={nonSCData} title="No Shortcut"/> : null}
            {scData.length > 0 ? <StatsGrid rowData={scData} title="Shortcut"/> : null}
            {glitchData.length > 0 ? <StatsGrid rowData={glitchData} title="Glitch"/> : null}
        </Layout>
    )
}

export default PlayerStats;