import {Alert, AlertTitle} from "@mui/material";
import React from "react";

const Message = ({severity, title, message, onClose}) => {
    return (
        <Alert
            severity={severity}
            onClose={onClose}
            sx={{whiteSpace: "pre-line"}}
            variant="outlined"
        >
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    )
}

export default Message;